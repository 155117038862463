
.container-fluid{
  width: 98% !important;
}
.dashboard{
  width: 100%;
  height: 100%;
  /* overflow-x: unset; */
}
.dashboard {
    /* background: #eef1f5; */
    font-family: Cairo-Bold;
    margin-top: -20px;
  }
  .dashboard .all-card {
    padding: 30px;
  }
  .dashboard .card{
    border-radius: 6px;
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
    background-color: #fff;
    margin-bottom: 30px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    cursor: pointer;
   }
  .dashboard .card2 {
    min-width: 200px;
    max-width: 350px;
    flex: 1;
    /* min-height: 100px; */
    height: 130px;
    /* max-height: 300px; */
    overflow: hidden;
  }


  .dashboard .card .border-left-pink {
    border-right: 4px solid #bd0404;
  }
  .dashboard .card .border-left-orange {
    border-right: 4px solid #c8b803;
  }
  .dashboard .card .border-left-yellow {
    border-right: 4px solid #ffd600;
  }
  .dashboard .card .border-left-blue {
    border-right: 4px solid #1E6A99;
  }
  .dashboard .card .border-left-green {
    border-right: 4px solid #04a321;
  }
  .dashboard .card .text-title {
    color: #8898aa;
    font-weight: 500;
    font-size: 14px;
  }
  .dashboard .card .text-amount {
    font-weight: 600;
  }
  .dashboard .card .icon-shape {
    border-radius: 50%;
    color: #fff;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    position: relative;

  }
  .dashboard .card .icon-canceled {
    background: #bd0404;
  }
  .dashboard .card .icon-wait {
    background: #c8b803;
  }
  .dashboard .card .icon-user {
    background: #ffd600;
  }
  .dashboard .card .icon-counts {
    background: #1E6A99;
  }
  .dashboard .card .icon-complete {
    background:  #04a321;
  }
  .dashboard .chart {
    padding: 30px;
  }
  
  .col-auto{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 992px) and (max-width: 1400px) {
    .dashboard .card .text-title {
      font-size: 13px;
    }
    .dashboard .card .text-amount {
      font-size: 18px;
    }
    .dashboard .card .icon-shape {
      width: 35px;
      height: 35px;
      font-size: 20px;
    }
  }
  @media (max-width: 360px) {
    .icon-shape {
      display: none !important;
    }
  }

  .dashboard .card .card-body{
    box-shadow: none!important;
  }


  .cardCoverd{
    position: absolute;
    /* top:75%; */
    right:0;
    left:0;
    bottom:0;
    width:100%;
    /* height: 100%; */
    display: none;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 324232;
    background-color: rgba(0,0,0,.2);
    transition: all 1s ease-in-out !important;
    top:100%;
  }
  .card:hover .cardCoverd{
    transition: top 1s ease-in-out;
    display: flex;
    /* top:75%; */
     /* animation: transition 1s ease-in-out linear; */
     animation-duration: 0.5s;
     animation-name: transition;
     animation-iteration-count: 1;
     animation-direction: normal;
     animation-play-state: running;
     animation-fill-mode: forwards;
  }
  .cardCoverd div{
    color:#fff;
    width: fit-content;
    height: 30px;
    padding: 0 20px;
    position: relative;
    top:3px
  }
  @keyframes transition {
    from{
      top:100%
    }
    to{
      top:75%
    }
  }