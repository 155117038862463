
.containerOfPreloading{
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 4000;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    backdrop-filter: blur(5px);
}

  
  section {
    flex: 1 1 25%;
  }
  
 

  .sk-cube-grid {
    width: 140px;
    height: 140px;
    padding: 1px;
    margin: auto;
    /* position: absolute; */
    /* top:50%;
    left:50%;
    transform:scale(-50%,-50%);  */
    /*
     * Spinner positions
     * 1 2 3
     * 4 5 6
     * 7 8 9
     */
  }
  /* .sk-cube{
    background-image: url('');
  } */
  .sk-cube-grid .sk-cube {
    width: 45px;
    height: 45px;
    background-size: 100% 100% !important;
    background-color: #1e6a99;
    /* margin-left: 2px;
    margin-right: 2px; */

    /* background-repeat: no-repeat; */
    mix-blend-mode: color-burn !important;
    /* background-color: #337ab7; */
    float: left;
    -webkit-animation: sk-cube-grid-scale-delay 1.3s infinite ease-in-out;
            animation: sk-cube-grid-scale-delay 1.3s infinite ease-in-out;
  }
  .sk-cube-grid .sk-cube-1 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .sk-cube-grid .sk-cube-2 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk-cube-grid .sk-cube-3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .sk-cube-grid .sk-cube-4 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .sk-cube-grid .sk-cube-5 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .sk-cube-grid .sk-cube-6 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk-cube-grid .sk-cube-7 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  .sk-cube-grid .sk-cube-8 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .sk-cube-grid .sk-cube-9 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  @-webkit-keyframes sk-cube-grid-scale-delay {
    0%, 70%, 100% {
      transform: scale3D(1, 1, 1);
    }
    35% {
      transform: scale3D(0, 0, 1);
    }
  }
  
  @keyframes sk-cube-grid-scale-delay {
    0%, 70%, 100% {
      transform: scale3D(1, 1, 1);
    }
    35% {
      transform: scale3D(0, 0, 1);
    }
  }
  