/* paginatinos tabs for grid here...  */
.paginationTabsBottom{
    width: 100%;
    background-color:white;
    box-shadow: 0px 2px 7px -4px rgb(0 0 0 / 64%);
    display:flex; 
    flex-wrap: wrap;
    justify-content:start;
    align-items: center;
    padding:10px 10px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    border-top:2px dashed lightgrey;
  }

  .paginationTabsBottom_sub{
    /* width: 85%; */
    flex:6;
    background-color:white;
    /* box-shadow: 0px 2px 7px -4px rgb(0 0 0 / 64%); */
    display:flex; 
    flex-wrap: wrap;
    justify-content:start;
    align-items: center;
    padding:10px 10px;
    /* border-bottom-left-radius:5px;
    border-bottom-right-radius:5px; */
    /* border-top:2px dashed lightgrey; */
  }

  .boxOfPagination{
    width: 75%;
  }
  .boxOfPagination1{
    width: 50%;
  
  }
  .pageNumberSet{
    width: 70px;
    height: 35px !important;
    font-size: 15px !important;
    border-radius: 2px;
    border: 1px solid lightgrey;
    padding: 2px 7px !important;
    outline-color: lightgrey !important;
    margin: 0 12px;
    

    /* outline-offset: 1px !important; */
  }
  .pageNumberSet::before{
    content: 'الصفحة';
    position: absolute;
    color: black;
    top: 10px;
    font-size: 10px;
    left:0;
    z-index:1000;
  }
  .scrollDataGrid{
    overflow-x: scroll !important;
  }
  .scrollDataGrid::-webkit-scrollbar{
    display: block !important;
    appearance: initial !important;
  }
  
  @media only screen and (max-width: 714px) {
    .paginationTabsBottom{
      /* width: 600px; */
    }
    .boxOfPagination{
      width: 50%;
    }
    .scrollDataGrid_horizontal{
      /* overflow-x:scroll !important; */
    }
  }
  
  @media only screen and (max-width: 650px) {
    .paginationTabsBottom{
      /* width: 640px; */
     justify-content: space-evenly;
    }
    .boxOfPagination{
      width: 50%;
    }
  }
  .ActionIcons{
    font-family:'Cairo' !important;
    color:grey;

  }