html{
  background-repeat: no-repeat !important;
  background-size: cover;
  background: none !important;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  overflow: hidden;
}
body{
  height: 100%;
  width: 100%;
  margin: 0;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  overflow:auto;

  /* overflow-y:scroll;
  overflow-x: var(100%-200px); */
}

 /* cario fonts  */
@font-face {
  font-family: "Cairo-Black";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Bold";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-ExtraLight";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Light";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-SemiBold";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Cairo-Medium";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-ExtraBold";   /*Can be any text*/
  src: local("Cario"),
    url("./assets/fonts/Cairo/Cairo-ExtraBold.ttf") format("truetype");
}

/* Rajdhani fonts  */
@font-face {
  font-family: "Rajdhani-Bold";   /*Can be any text*/
  src: local("Rajdhani"),
    url("./assets/fonts/Rajdhani/Rajdhani-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Rajdhani-Light";   /*Can be any text*/
  src: local("Rajdhani"),
    url("./assets/fonts/Rajdhani/Rajdhani-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Rajdhani-Medium";   /*Can be any text*/
  src: local("Rajdhani"),
    url("./assets/fonts/Rajdhani/Rajdhani-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Rajdhani";   /*Can be any text*/
  src: local("Rajdhani"),
    url("./assets/fonts/Rajdhani/Rajdhani-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rajdhani-SemiBold";   /*Can be any text*/
  src: local("Rajdhani"),
    url("./assets/fonts/Rajdhani/Rajdhani-SemiBold.ttf") format("truetype");
}
/* end  */


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  /* box-shadow:  0 0 5px rgba(128, 128, 128, 0.716) !important;  */
  /* border-radius: 10px; */
  /* border-top-left-radius: 15px; */
  /* border-bottom-left-radius: 15px; */

  background: #ffffff; 

}
 
/* Handle */
*::-webkit-scrollbar-thumb {
  background: #349CDD; 
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #349CDD; 
}

.Toastify__close-button{
  margin-top: 14px !important;
 }
.Toastify__toast--error {
  /* margin-left: 15px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}
.Toastify__toast-body{
  margin-left: 20px !important;
}
.Toastify__toast-body{
  font-family: 'Cairo-Bold';
}


.MuiPickersPopper-root{
  z-index:99999999 !important
}
.MuiPickersPopper-root{
  /* top:50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  backdrop-filter:blur(5px); */
  
}

/* .MuiAutocomplete-root ::before{
  top:0 !important;
  left:0 !important;
  bottom:0 !important;
  width:5px !important;
  height: 100% !important;
  background: blue !important;
  overflow: hidden !important;
  transition:all 0.9s ease !important;
  z-index: 3534000 !important;
  position: absolute !important;
} */
.MuiAutocomplete-paper .MuiAutocomplete-option{
  font-family: 'Cairo';
}
.MuiAutocomplete-paper{
  z-index: 500000 !important;
}
.MuiAutocomplete-paper .MuiAutocomplete-paper	{
  z-index: 500000 !important;
}

.iconAddRelationType:hover{
  transition: 0.4s ease-in-out;
  transform: scale(1.2);
}

.tooltip {
  position: absolute;
  color: #fff;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}
canvas,img{
  object-fit: cover !important;
  background-position: center center !important;
}


/* width */
.CustomerFinancialSummaryTab::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.CustomerFinancialSummaryTab::-webkit-scrollbar-track {
  /* box-shadow:  0 0 5px rgba(128, 128, 128, 0.716) !important;  */
  /* border-radius: 10px; */
  /* border-top-left-radius: 15px; */
  /* border-bottom-left-radius: 15px; */

  background: #ffffff; 

}
 
/* Handle */
.CustomerFinancialSummaryTab::-webkit-scrollbar-thumb {
  background: #994D1EA2; 
  border-radius: 5px;
}

/* Handle on hover */
.CustomerFinancialSummaryTab::-webkit-scrollbar-thumb:hover {
  background: #994D1EA2; 
}

.swal-title,.swal-button{
  font-family:'Cairo-Bold';
}
.swal-text{
  font-family: 'Cairo-Medium';
}

.dx-empty-message{
  display: none;
}
.ancorTage{
  text-decoration: underline;
  color: blue;
}

 
.Toastify__toast-body,.Toastify__toast,.Toastify__toast-container{
  width: fit-content !important;
}